import { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	CircularProgress,
	Divider,
	Button,
} from "@mui/material";
// cmp
import ColorTemperatureSlider from "../color-temperature-slider";
import ColorButton from "../color-button";
import ColorSelector from "../dialog-selectors/ColorSelector";
import ColorTemplatesRgb from "../color-templates-rgb";
import ColorTemplatesTemp from "../color-templates-temp";
import ListItemContainer from "../ListItemContainer";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
import useIsSmallScreen from "../../hooks/useIsSmallScreen";
// services
import DeviceType from "../../services/device-type";
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { KELVIN_UNIT, DEFAULT_COLOR_TEMPERATURE_MIRED_MIN, DEFAULT_COLOR_TEMPERATURE_MIRED_MAX, getColorPrimariesFromCluster, convertColorRGBToColorXY, rgbToHex, convertMiredToKelvin } from "../../services/color";
import { decimal2Hex, minMax } from "../../services/utils";
import { icons } from "@local/theme";
// type
import type { ReactNode } from "react";
import type { EpDevice } from "../../types/device";
import type { DeviceType as DeviceTypeT } from "../../types/device-type";
import type { IncapsCluster0300 } from "../../types/cluster";
import type { CmdSendActionCmd, CmdSendGeneralCmdWrite } from "../../types/message";
import type { ColorRGB } from "../../types/misc";

type ColorModeTuya = (typeof Constants.ColorModesTuya)[number];

const getColorCapabilityAttribute = (cluster: IncapsCluster0300): number => {
	if (typeof cluster[ClusterConstants.D0300.Attributes.ColorCapabilities] === "number") {
		return cluster[ClusterConstants.D0300.Attributes.ColorCapabilities] as number;
	} else if (typeof cluster[ClusterConstants.D0300.Attributes.BulbType] === "number") {
		switch (cluster[ClusterConstants.D0300.Attributes.BulbType]) {
			case 0:
				return 0;
			case Constants.BulbType.ColorTemperature:
				return Constants.ColorCapabilities.ColorTemperature;
			case Constants.BulbType.ColorXY:
				return Constants.ColorCapabilities.ColorXY;
			default:
				return Constants.ColorCapabilities.ColorXY | Constants.ColorCapabilities.ColorTemperature;
		}
	}
	return Constants.ColorCapabilities.HueSaturation | Constants.ColorCapabilities.EnhancedHue | Constants.ColorCapabilities.ColorLoop | Constants.ColorCapabilities.ColorXY | Constants.ColorCapabilities.ColorTemperature;
};

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceTypeT<"0300">;
};

const D0300 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const isSmallScreen = useIsSmallScreen();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);

	const min = cluster?.[ClusterConstants.D0300.Attributes.ColorTempMiredPhysicalMin] ?? DEFAULT_COLOR_TEMPERATURE_MIRED_MIN;
	const max = cluster?.[ClusterConstants.D0300.Attributes.ColorTempMiredPhysicalMax] ?? DEFAULT_COLOR_TEMPERATURE_MIRED_MAX;

	const [temperature, setTemperature] = useDynamicUpdateState(minMax(cluster?.[ClusterConstants.D0300.Attributes.ColorTemperature] ?? min, min, max));
	const [showColorDialog, setShowColorDialog] = useState(false);
	const [showSaveSuccess, setShowSaveSuccess] = useState<number | undefined>(undefined);
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	if (cluster === undefined) {
		return null;
	}

	const colorCapabilityAttribute = getColorCapabilityAttribute(cluster);
	const isColorLoopActive = Boolean(cluster[ClusterConstants.D0300.Attributes.ColorLoopActive]);
	const colorMode = cluster[ClusterConstants.D0300.Attributes.ColorModeTuya];

	const entries: Array<ReactNode> = [];
	if (colorMode !== undefined) {
		const setColorModeValue = (mode: ColorModeTuya) => {
			if (colorMode === mode.id) {
				return;
			}

			const cmd = {
				action: "sendGeneralCmd",
				gatewayId: props.epDevice.gwId,
				srcGw: props.epDevice.srcGw,
				deviceId: props.epDevice.id,
				endpoint: props.epDevice.epId,
				caps: props.deviceType.cap,
				clusterId: props.deviceType.clusterId,
				cmdId: Constants.GeneralCmdIds.WriteAttribute,
				values: [{
					id: ClusterConstants.D0300.Attributes.ColorModeTuya,
					datatype: Constants.DataType.UInt8Bit,
					value: mode.value,
				}],
			} as const satisfies CmdSendGeneralCmdWrite<"0300">;
			send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setShowGenericErrorMsg(undefined);
				} else {
					setShowGenericErrorMsg(Date.now());
				}
			});
		};

		entries.push(
			<ListItem>
				<ListItemText primary={t("clusters.DFF00.title")} />
				<ListItemContainer>
					{Constants.ColorModesTuya.map((mode) => (
						<Button
							key={mode.id}
							variant="contained"
							color={(colorMode === mode.id) ? "primary" : "neutral"}
							disableRipple={colorMode === mode.id}
							disableFocusRipple={colorMode === mode.id}
							onClick={() => (setColorModeValue(mode))}
							sx={{ marginLeft: "10px", cursor: (colorMode === mode.id) ? "default" : "pointer" }}
						>
							{t(mode.l10n)}
						</Button>
					))}
				</ListItemContainer>
			</ListItem>
		);
	}
	if (colorCapabilityAttribute & Constants.ColorCapabilities.ColorXY) {
		const handleHueChange = (colorRGB: ColorRGB) => {
			const colorPrimaries = getColorPrimariesFromCluster(cluster);
			const { currentX, currentY } = convertColorRGBToColorXY(colorRGB, colorPrimaries);

			const cmd = {
				action: "sendActionCmd",
				gatewayId: props.epDevice.gwId,
				srcGw: props.epDevice.srcGw,
				deviceId: props.epDevice.id,
				endpoint: props.epDevice.epId,
				caps: props.deviceType.cap,
				clusterId: props.deviceType.clusterId,
				cmdId: ClusterConstants.D0300.CmdIds.MoveToColor,
				value: `${decimal2Hex(currentX, 4)},${decimal2Hex(currentY, 4)},0001`,
			} as const satisfies CmdSendActionCmd;
			send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setShowSaveSuccess(Date.now());
					setShowGenericErrorMsg(undefined);
				} else {
					setShowGenericErrorMsg(Date.now()); // TODO: better error message.
				}
			});
		};

		const color = props.deviceType.formatValue(cluster);

		entries.push(
			<>
				<ListItemButton onClick={() => (setShowColorDialog(true))}>
					<ListItemText primary={t("clusters.D0300.chooseColor")} />
					<ColorButton color={rgbToHex(color)} />
					<ListItemIcon><icons.ChevronRight /></ListItemIcon>
				</ListItemButton>
				<ColorSelector
					open={showColorDialog}
					color={color}
					epDevice={props.epDevice}
					onChange={handleHueChange}
					onClose={() => (setShowColorDialog(false))}
				/>
				<Divider />
				<ListItem sx={{ justifyContent: isSmallScreen ? undefined : "flex-end" }}>
					<ListItemContainer style={{ width: isSmallScreen ? "100%" : "50%", boxSizing: "border-box", display: "flex", justifyContent: "space-between", padding: "4px 6px" }}>
						<ColorTemplatesRgb onColorClick={handleHueChange} />
					</ListItemContainer>
				</ListItem>
			</>
		);
	}
	if (colorCapabilityAttribute & Constants.ColorCapabilities.ColorLoop) {
		const handleColorLoop = () => {
			const cluster0006 = props.epDevice.getClusterByCapAndClusterId(DeviceType.D0006.cap, DeviceType.D0006.clusterId);
			if (cluster0006 !== undefined && !isColorLoopActive && !DeviceType.D0006.getValue(cluster0006)) {
				const cmd = {
					action: "sendActionCmd",
					gatewayId: props.epDevice.gwId,
					srcGw: props.epDevice.srcGw,
					deviceId: props.epDevice.id,
					endpoint: props.epDevice.epId,
					caps: DeviceType.D0006.cap,
					clusterId: DeviceType.D0006.clusterId,
					cmdId: ClusterConstants.D0006.CmdIds.TurnOnDevice,
				} as const satisfies CmdSendActionCmd;
				send(cmd, (error, msg) => {
					if (!error && msg?.payload.status === "ok") {
						setShowGenericErrorMsg(undefined);
					} else {
						setShowGenericErrorMsg(Date.now());
					}
				});
			}
			const cmd = {
				action: "sendActionCmd",
				gatewayId: props.epDevice.gwId,
				srcGw: props.epDevice.srcGw,
				deviceId: props.epDevice.id,
				endpoint: props.epDevice.epId,
				caps: props.deviceType.cap,
				clusterId: props.deviceType.clusterId,
				cmdId: ClusterConstants.D0300.CmdIds.ColorLoop,
				value: isColorLoopActive ? ClusterConstants.D0300.CmdPayloads.StopLoop : ClusterConstants.D0300.CmdPayloads.StartLoop,
			} as const satisfies CmdSendActionCmd;
			send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setShowGenericErrorMsg(undefined);
				} else {
					setShowGenericErrorMsg(Date.now());
				}
			});
		};

		entries.push(
			<ListItem sx={{ justifyContent: "space-between" }}>
				{isColorLoopActive
					? (
						<div style={{ display: "flex" }}>
							<CircularProgress size={30} sx={{ marginRight: "16px" }} />
							<ListItemText primary={t("clusters.D0300.colorLoopActive")} />
						</div>
					)
					: t("clusters.D0300.colorLoopInActive")
				}
				<ListItemContainer>
					<Button onClick={handleColorLoop}>
						{t(isColorLoopActive ? "clusters.D0300.stopLoop" : "clusters.D0300.startLoop")}
					</Button>
				</ListItemContainer>
			</ListItem>
		);
	}
	if (colorCapabilityAttribute & Constants.ColorCapabilities.ColorTemperature) {
		const handleTemperatureChangeComplete = (temperatureValue: number) => {
			setTemperature(temperatureValue);
			const cmd = {
				action: "sendActionCmd",
				gatewayId: props.epDevice.gwId,
				srcGw: props.epDevice.srcGw,
				deviceId: props.epDevice.id,
				endpoint: props.epDevice.epId,
				caps: props.deviceType.cap,
				clusterId: props.deviceType.clusterId,
				cmdId: ClusterConstants.D0300.CmdIds.MoveToColorTemp,
				value: `${decimal2Hex(temperatureValue, 4)},0001`,
			} as const satisfies CmdSendActionCmd;
			send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setShowGenericErrorMsg(undefined);
				} else {
					setShowGenericErrorMsg(Date.now());
				}
			});
		};

		entries.push(
			<>
				<ListItem sx={isSmallScreen ? { flexDirection: "column", alignItems: "flex-start" } : { flexDirection: "row" }}>
					<ListItemText
						primary={t("clusters.D0300.chooseTempColor")}
						secondary={`${Math.round(convertMiredToKelvin(temperature))}${KELVIN_UNIT}`}
						sx={isSmallScreen ? { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" } : {}}
					/>
					<ListItemContainer style={isSmallScreen ? { width: "100%" } : { width: "calc(50% - 10px)", marginRight: "10px" }}>
						<ColorTemperatureSlider
							min={min}
							max={max}
							value={temperature}
							onChange={setTemperature}
							onChangeCommitted={handleTemperatureChangeComplete}
						/>
					</ListItemContainer>
				</ListItem>
				<Divider />
				<ListItem sx={{ justifyContent: isSmallScreen ? undefined : "flex-end" }}>
					<ListItemContainer style={{ width: isSmallScreen ? "100%" : "50%", boxSizing: "border-box", display: "flex", justifyContent: "space-between", padding: "4px 6px" }}>
						<ColorTemplatesTemp
							min={min}
							max={max}
							onColorClick={handleTemperatureChangeComplete}
						/>
					</ListItemContainer>
				</ListItem>
			</>
		);
	}

	return (
		<>
			{entries.map((entry, index) => (
				<Fragment key={index}>
					{(index > 0) && <Divider />}
					{entry}
				</Fragment>
			))}
			<Toast
				autoHideDuration={3000}
				severity="success"
				open={showSaveSuccess}
				onClose={setShowSaveSuccess}
				message={t("toast.saved")}
			/>
			<Toast
				autoHideDuration={6000}
				severity="error"
				open={showGenericErrorMsg}
				onClose={setShowGenericErrorMsg}
				message={t("toast.genericErrorMsg")}
			/>
		</>
	);
};

D0300.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
		formatValue: PropTypes.func.isRequired,
	}).isRequired,
};

export default D0300;
