import { memo } from "react";
import { Box, Slider } from "@mui/material";
import { styled } from "@mui/material/styles";

type Props = Readonly<{
	orientation: "horizontal" | "vertical";
	min: number;
	max: number;
	value: number;
	onChange: (value: number) => void;
	onChangeCommitted: (value: number) => void;
}>;

const TRACK_THICKNESS = 4;
const BORDER_THICKNESS = 3;

const StyledSlider = styled(Slider)(
	({theme, orientation}) => ({
		"padding": "0px !important",
		"width": (orientation === "vertical") ? `${TRACK_THICKNESS}px !important` : undefined,
		"height": (orientation === "vertical") ? undefined : `${TRACK_THICKNESS}px !important`,
		"top": (orientation === "vertical") ? undefined : "-16px",
		"left": (orientation === "vertical") ? "-2px" : undefined,
		"borderRadius": `${TRACK_THICKNESS / 2}px`,
		"& .MuiSlider-thumb": {
			width: "18px",
			height: "18px",
			border: `${BORDER_THICKNESS}px solid ${theme.palette.primary.main}`,
			backgroundColor: theme.palette.primary.contrastText,
		},
		"& .MuiSlider-rail": {
			width: (orientation === "vertical") ? `${TRACK_THICKNESS}px !important` : undefined,
			height: (orientation === "vertical") ? undefined : `${TRACK_THICKNESS}px !important`,
			borderRadius: `${TRACK_THICKNESS / 2}px`,
			backgroundColor: "#e9e9e9",
			opacity: "initial !important",
		},
		"& .MuiSlider-track": {
			width: (orientation === "vertical") ? `${TRACK_THICKNESS}px !important` : undefined,
			height: (orientation === "vertical") ? undefined : `${TRACK_THICKNESS}px !important`,
			borderRadius: `${TRACK_THICKNESS / 2}px`,
			backgroundColor: theme.palette.primary.main,
		},
	})
);

const PanTiltSlider = ({ orientation, min, max, value, onChange, onChangeCommitted }: Props) => (
	<Box
		component="div"
		sx={{
			position: "absolute",
			width: (orientation === "vertical") ? `${TRACK_THICKNESS}px` : undefined,
			height: (orientation === "vertical") ? undefined : `${TRACK_THICKNESS}px`,
			top: (orientation === "vertical") ? "26px" : undefined,
			left: (orientation === "vertical") ? undefined : "26px",
			bottom: (orientation === "vertical") ? "76px" : "24px",
			right: (orientation === "vertical") ? "24px" : "76px",
			padding: `${BORDER_THICKNESS}px`,
			borderRadius: `${TRACK_THICKNESS / 2 + BORDER_THICKNESS}px`,
			backgroundColor: "rgba(0, 0, 0, 0.4)",
		}}
	>
		<StyledSlider
			orientation={orientation}
			min={min}
			max={max}
			value={value}
			onChange={(event, value) => (onChange(value))}
			onChangeCommitted={(event, value) => (onChangeCommitted(value))}
		/>
	</Box>
);

export default memo(PanTiltSlider);
