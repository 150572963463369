import { useState, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { List, ListItem, ListItemText } from "@mui/material";
// cmp
import ListItemContainer from "./ListItemContainer";
import ColorButton from "./color-button";
import UnitDisplay from "./devices/UnitDisplay";
import DimSlider from "./dim-slider";
import Toast from "./Toast";
// hooks
import useSend from "../hooks/useSend";
import useDynamicUpdateState from "../hooks/useDynamicUpdateState";
// services
import DeviceType from "../services/device-type";
import ClusterConstants from "../services/cluster-constants";
import { rgbToHex } from "../services/color";
import { decimal2Hex } from "../services/utils";
// types
import type { EpDevice } from "../types/device";
import type { ColorRGB } from "../types/misc";
import type { CmdSendActionCmd } from "../types/message";

type Props = Readonly<{
	color: ColorRGB;
	epDevice: EpDevice;
}>;

const LightControls = ({ color, epDevice }: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster0008 = epDevice.getClusterByCapAndClusterId(DeviceType.D0008.cap, DeviceType.D0008.clusterId)!;

	const [dimLevel, setDimLevel] = useDynamicUpdateState(DeviceType.D0008.getValue(cluster0008));
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const handleChangeCommitted = useCallback((value: number) => {
		const cmd = {
			action: "sendActionCmd",
			gatewayId: epDevice.gwId,
			srcGw: epDevice.srcGw,
			deviceId: epDevice.id,
			endpoint: epDevice.epId,
			caps: DeviceType.D0008.cap,
			clusterId: DeviceType.D0008.clusterId,
			cmdId: ClusterConstants.D0008.CmdIds.MoveToLevelWithOnOff,
			value: `${decimal2Hex(Math.round(value * 255 / 100), 2)},ffff`,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	}, [epDevice.gwId, epDevice.srcGw, epDevice.id, epDevice.epId]);

	return (
		<>
			<List disablePadding={true}>
				<ListItem disableGutters={true}>
					<ColorButton color={rgbToHex(color)} />
					<ListItemText
						sx={{ marginLeft: "20px", marginRight: "8px" }}
						primary={
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								{t("clusters.D0008.title", { context: epDevice.stringOverrideKey })}
								<UnitDisplay
									values={[dimLevel]}
									digits={DeviceType.D0008.getDigits()}
									unit={DeviceType.D0008.getUnit()}
									sameFontSize={true}
								/>
							</div>
						}
						secondary={
							<DimSlider
								value={dimLevel}
								onChange={setDimLevel}
								onChangeCommitted={handleChangeCommitted}
							/>
						}
					/>
					<ListItemContainer>
						<DeviceType.D0006.cmp // eslint-disable-line @stylistic/jsx-pascal-case
							epDevice={epDevice}
							deviceType={DeviceType.D0006}
						/>
					</ListItemContainer>
				</ListItem>
			</List>
			<Toast
				autoHideDuration={6000}
				severity="error"
				open={showGenericErrorMsg}
				onClose={setShowGenericErrorMsg}
				message={t("toast.genericErrorMsg")}
			/>
		</>
	);
};

export default memo(LightControls);
