import { memo } from "react";
import { useTranslation } from "react-i18next";
// types
import type { ReactEventHandler } from "react";

type Props = Readonly<{
	url: string;
	onLoad: ReactEventHandler<HTMLImageElement>;
	onError: ReactEventHandler<HTMLImageElement>;
}>;

const ImagePlayer = (props: Props) => {
	const { t } = useTranslation();

	return (
		<img
			id="streaming-img"
			src={props.url}
			alt={t("clusters.DC38A.video")}
			height="auto"
			style={{ display: "block", width: "100%", maxHeight: "600px" }}
			onLoad={props.onLoad}
			onError={props.onError}
			referrerPolicy="no-referrer" // default "strict-origin-when-cross-origin"
		/>
	);
};

export default memo(ImagePlayer);
