import reactStringReplace from "react-string-replace";
// types
import type { ReactNode } from "react";

type Props = {
	originalString: string;
	replaceData: Record<`{${string}}`, (match: string, index: number, offset: number) => ReactNode>;
};

const ReactMultipleStringReplace = ({ originalString, replaceData }: Props) => {
	let replacedText = originalString as string | Array<ReactNode>;

	for (const searchKey of Object.keys(replaceData)) {
		replacedText = reactStringReplace(replacedText, searchKey, replaceData[searchKey]);
	}

	return replacedText;
};

export default ReactMultipleStringReplace;
