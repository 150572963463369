import { Slider } from "@mui/material";
import { styled } from "@mui/material/styles";

type Props = Readonly<{
	min: number;
	max: number;
	value: number;
	onChange: (temperature: number) => void;
	onChangeCommitted: (temperature: number) => void;
}>;

const StyledSlider = styled(Slider)({
	"& .MuiSlider-rail": {
		height: "6px",
		padding: 0,
		background: "linear-gradient(to right, #ff9329 0%, #ffd6aa 22%, #fff1e0 42%, #ffffff 62%, #c9e2ff 82%, #409cff 100%) !important",
		borderRadius: "3px",
		opacity: 1,
	},
});

const ColorTemperatureSlider = ({ min, max, value, onChange, onChangeCommitted }: Props) => (
	<StyledSlider
		min={-max}
		max={-min}
		track={false}
		value={-value}
		onChange={(_event, _value) => (onChange(-(_value as number)))}
		onChangeCommitted={(_event, _value) => (onChangeCommitted(-(_value as number)))}
	/>
);

export default ColorTemperatureSlider;
