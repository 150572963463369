import { forwardRef } from "react";
import { styled } from "@mui/material/styles";
// types
import type { CSSProperties, MouseEventHandler, ReactNode } from "react";

type Props = Readonly<{
	children: ReactNode;
	className?: string;
	style?: CSSProperties;
	onClick?: MouseEventHandler<HTMLDivElement>;
}>;

const ListItemContainer = forwardRef<HTMLDivElement, Props>(({ children, className = undefined, style = undefined, onClick = undefined }, ref) => (
	<div ref={ref} className={className} style={style} onClick={onClick}>
		{children}
	</div>
));

if (process.env.NODE_ENV !== "production") {
	ListItemContainer.displayName = "ListItemContainer";
}

const ListItemContainerStyled = styled(ListItemContainer, { name: "ListItemContainer" })({
	display: "inline-flex",
	justifyContent: "flex-end",
	alignItems: "center",
});

export default ListItemContainerStyled;
