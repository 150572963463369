// services
import { refreshAccessToken } from "./authenticator";

let refreshTimeoutId: number | undefined = undefined;
let retryTimeoutId: number | undefined = undefined;

export const stopAccessTokenAutoRefresh = (): void => {
	window.clearTimeout(refreshTimeoutId);
	window.clearTimeout(retryTimeoutId);
	refreshTimeoutId = undefined;
	retryTimeoutId = undefined;
};

const REFRESH_TIME_BEFORE_EXPIRE = 5 * 60 * 1000;
const RETRY_TIME_AFTER_ERROR = 60 * 1000;

export const restartAccessTokenAutoRefresh = (expiresAt: number): void => {
	stopAccessTokenAutoRefresh();

	refreshTimeoutId = window.setTimeout(async () => {
		try {
			await refreshAccessToken(true);
		} catch (error) {
			console.error("restartAccessTokenAutoRefresh failed:", error);
			retryTimeoutId = window.setTimeout(() => {
				restartAccessTokenAutoRefresh(expiresAt);
			}, RETRY_TIME_AFTER_ERROR);
		}
	}, Math.max(expiresAt - Date.now() - REFRESH_TIME_BEFORE_EXPIRE, 0));
};
