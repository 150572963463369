import { Capacitor } from "@capacitor/core";
// @ts-expect-error: globalThis.appInfo
import AppInfo from "@local/appInfo";
import { getDeviceId } from "../hooks/useDeviceInfo";
// types
import type { DeviceId } from "../types/device";

export interface RestHeaders {
	"x-roc-os": string;
	"x-roc-ver": string;
	"x-mac": DeviceId;
}

export const getRestHeaders = async (): Promise<RestHeaders> => ({
	"x-roc-os": Capacitor.getPlatform(),
	"x-roc-ver": AppInfo.build,
	"x-mac": await getDeviceId(),
} as const satisfies RestHeaders);
