import { memo } from "react";
import { Slider } from "@mui/material";

type Value = number; // could be also Array<number> for range values

type Props = Readonly<{
	value: Value;
	onChange: (value: Value) => void;
	onChangeCommitted: (value: Value) => void;
}>;

const DEFAULT_ON_CHANGE_COMMITTED = () => {};

const DimSlider = ({ value, onChange, onChangeCommitted = DEFAULT_ON_CHANGE_COMMITTED, ...other }: Props) => (
	<Slider
		min={0}
		max={100}
		step={1}
		value={value}
		onChange={(event, value) => (onChange(value as Value))}
		onChangeCommitted={(event, value) => (onChangeCommitted(value as Value))}
		sx={{ verticalAlign: "middle" }}
		{...other}
	/>
);

export default memo(DimSlider);
