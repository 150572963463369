import { WebPlugin } from "@capacitor/core";
import type { NativeLoginPlugin, OptionsLogin, OptionsLogout, Tokens, LoginResponse } from "./definitions";

export class NativeLoginWeb extends WebPlugin implements NativeLoginPlugin {

	async login(options: OptionsLogin): Promise<LoginResponse> {
		globalThis.location.href = options.url;
		return Promise.resolve({}); // TODO: check return value
	}

	async logout(options: OptionsLogout): Promise<void> {
		globalThis.location.href = options.url;
		return Promise.resolve();
	}

	// Not implemented for iOS yet
	// async close(): Promise<void> {
	// 	return Promise.resolve();
	// }

	// eslint-disable-next-line @typescript-eslint/require-await
	async getTokens(): Promise<Tokens> {
		throw this.unimplemented("Not implemented on web.");
	}

	// eslint-disable-next-line @typescript-eslint/require-await, @typescript-eslint/no-unused-vars
	async setTokens(_options: Tokens): Promise<void> {
		throw this.unimplemented("Not implemented on web.");
	}

	// eslint-disable-next-line @typescript-eslint/require-await
	async migrateTokens(): Promise<void> {
		throw this.unimplemented("Not implemented on web.");
	}

	// Only relevant for iOS
	// eslint-disable-next-line @typescript-eslint/require-await
	async checkRefreshTokens(): Promise<void> {
		throw this.unimplemented("Not implemented on web.");
	}

	// Only relevant for Android
	// eslint-disable-next-line @typescript-eslint/require-await
	async restartBackgroundRefresh(): Promise<void> {
		throw this.unimplemented("Not implemented on web.");
	}

}
