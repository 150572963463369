import { EventEmitter } from "events";
// services
import Glient from "./glient";
import i18n from "./i18n";
import User from "./user";
import Gateways from "./gateways";
import History from "./history";
import { getCurrencyByCountry } from "./currency";
import Constants from "./constants";
import { migrateSelectedGateway } from "./native-data-migration";
import { StorageKeys, Storage } from "./storage";
// types
import type { ReadonlyDeep } from "type-fest";
import type { CmdGatewayActionGatewayDefault, MsgBroadcastNewDeviceJoining } from "../types/message";
import type { GatewayId, TempUnit, Gateway as GatewayT, Message } from "../types/gateway";
import type { DeviceObj, JoiningDevices } from "../types/device";

/**
 * A Service class, used to categories a gateway.
 *
 * @class Gateway
 *
 * @event setDefault
 * @event selectedGatewayChanged
 * @event statusChanged
 * @event joiningDevicesChanged
 * @extends EventEmitter
 */
class Gateway extends EventEmitter {

	#selectedGateway: GatewayT | undefined = undefined;
	#joiningDevices: JoiningDevices = [];

	#loadSelectedGateway() {
		const selectedGatewayId = Storage.get(StorageKeys.selectedGatewayId);
		if (selectedGatewayId) {
			const gateway = Gateways.getGateways(Constants.Gateway.Kind.Gateway).find((gateway) => (gateway.id === selectedGatewayId));
			if (gateway) {
				this.selectGateway(gateway, false);
			} else {
				this.#selectedGateway = undefined;
			}
		} else {
			this.#selectedGateway = undefined;
		}
	}

	#storeSelectedGateway() {
		Storage.set(StorageKeys.selectedGatewayId, this.#selectedGateway?.id);
	}

	public get selectedGateway(): GatewayT | undefined {
		return this.#selectedGateway;
	}

	public get selectedGatewayId(): GatewayId | undefined {
		return this.#selectedGateway?.id;
	}

	public get selectedGatewayTemperature(): TempUnit {
		return this.#selectedGateway?.tempUnit?.toUpperCase() as TempUnit | undefined ?? Constants.TempUnit.Celsius;
	}

	/**
	 * Sets the default gateway for account and fire the setDefault
	 * event based on silent param.
	 * @param {Object} account
	 * @param {Boolean} emitOnSetDefault
	 *
	 * @event setDefault
	 */
	public setDefault(gateway: GatewayT | undefined, emitOnSetDefault: boolean = true): void {
		if (this.#selectedGateway?.id !== gateway?.id) {
			this.#joiningDevices = [];
			this.emit("joiningDevicesChanged", [...this.#joiningDevices]);
		}
		if (this.#selectedGateway?.status !== gateway?.status) {
			this.emit("statusChanged", gateway?.status);
		}
		this.#selectedGateway = gateway;
		this.#storeSelectedGateway();
		if (gateway && Storage.get(StorageKeys.electricityUnitPriceCurrency, { GWID: gateway.id }, { GWID: gateway.srcGw }) === null) {
			const currency = getCurrencyByCountry(gateway.country ?? User.user?.country);
			Storage.set(StorageKeys.electricityUnitPriceCurrency, currency, { GWID: gateway.id });
		}
		if (emitOnSetDefault) {
			this.emit("setDefault", this.#selectedGateway);
		}
	}

	public setInitGateway(): void {
		if (globalThis.localStorage.getItem("tempSelectedGateway") !== null) { // TODO: improve
			migrateSelectedGateway();
			return;
		}

		this.#loadSelectedGateway();

		if (this.#selectedGateway === undefined) {
			const gateways = Gateways.getSortedGateways();
			const ownedGateway = gateways.find((gateway) => (gateway.rbac & Constants.Gateway.RBAC.Owner));
			if (ownedGateway) {
				this.selectGateway(ownedGateway, false);
				return;
			}
			const userGateway = gateways.find((gateway) => (gateway.rbac & Constants.Gateway.RBAC.User));
			if (userGateway) {
				this.selectGateway(userGateway, false);
				return;
			}

			this.setDefault(undefined);
		}
	}

	/**
	 * @returns {Object|null} String error message
	 */
	public getMessage(): Message | null {
		if (Gateways.loaded) {
			const gateways = Gateways.getGateways(Constants.Gateway.Kind.Gateway);
			if (gateways.length === 0) {
				return {
					title: i18n.t("gatewayMessages.noGateway.title", { USERNAME: User.userInfo?.name ?? "" }),
					message: i18n.t("gatewayMessages.noGateway.message"),
				} as const satisfies Message;
			}
			if (this.#selectedGateway?.status === Constants.Gateway.Status.Unreachable) {
				return i18n.t("gatewayMessages.unreachable", { returnObjects: true });
			}
		}
		return null;
	}

	public selectGateway(gateway: GatewayT, emitOnChange: boolean = true): void {
		this.setDefault(gateway);
		this.fetchDevicePairingHistory();
		History.clearAllAttributeReports();
		if (emitOnChange) {
			this.emit("selectedGatewayChanged", gateway);
		}
	}

	public get joiningDevices(): ReadonlyDeep<JoiningDevices> {
		return this.#joiningDevices;
	}

	public clearJoiningDevices(): void {
		this.#joiningDevices = [];
		this.emit("joiningDevicesChanged", []);
	}

	/**
	 * Fetches the pairing history.
	 */
	public fetchDevicePairingHistory(): void {
		this.clearJoiningDevices();

		if (this.#selectedGateway && this.#selectedGateway.status !== Constants.Gateway.Status.Unreachable) {
			const cmd = {
				action: "gatewayAction",
				module: "gateway",
				function: "get_pairing_history",
				params: [],
				gatewayId: this.selectedGatewayId!,
			} as const satisfies CmdGatewayActionGatewayDefault;
			Glient.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.#joiningDevices = msg.payload.data;
					this.emit("joiningDevicesChanged", [...this.#joiningDevices]);
				}
			});
		}
	}

	public updateJoiningDevicesByDevice(device: DeviceObj) {
		const joiningDevice = this.#joiningDevices.find((pairingDevice) => (pairingDevice.id === device.id));
		if (joiningDevice) {
			// Update by reference
			joiningDevice.name = device.name;
			this.emit("joiningDevicesChanged", [...this.#joiningDevices]);
		}
	}

	/**
	 * Listen to newDeviceJoining broadcast and emits the same event.
	 * @param {Object} msg
	 * @event joiningDevicesChanged
	 */
	public handleNewDeviceJoiningBroadcast(msg: MsgBroadcastNewDeviceJoining): void {
		const index = this.#joiningDevices.findIndex((joiningDevice) => (joiningDevice.id === msg.payload.id));
		if (index === -1) {
			this.#joiningDevices.push(msg.payload);
		} else {
			this.#joiningDevices[index] = msg.payload;
		}
		this.emit("joiningDevicesChanged", [...this.#joiningDevices]);
	}

}

export default (new Gateway());
