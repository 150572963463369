import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	Button,
	Divider,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
// cmp
import ListItemContainer from "../ListItemContainer";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { hex2Decimal } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendActionCmd } from "../../types/message";
import type { RelativePathPrefix } from "../../types/misc";

type MowerActionModeValue = typeof Constants.AvailableMowerActionModes[number]["value"];

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"FF84">;
	relativePathPrefix: RelativePathPrefix;
};

const DISABLED_BUTTONS = {
	[ClusterConstants.DFF84.CmdPayloads.Mow]: [Constants.MowerStates.InLawn, Constants.MowerStates.ServiceMode, Constants.MowerStates.SoftwareUpdateMode, Constants.MowerStates.LowPowerMode, Constants.MowerStates.LeavingDock],
	[ClusterConstants.DFF84.CmdPayloads.Dock]: [Constants.MowerStates.InDock, Constants.MowerStates.ReturningToDock, Constants.MowerStates.ServiceMode, Constants.MowerStates.SoftwareUpdateMode, Constants.MowerStates.LowPowerMode, Constants.MowerStates.LeavingDock],
	[ClusterConstants.DFF84.CmdPayloads.Pause]: [Constants.MowerStates.InDock, Constants.MowerStates.Paused, Constants.MowerStates.ServiceMode, Constants.MowerStates.SoftwareUpdateMode, Constants.MowerStates.Unknown],
} as const;

const DFF84 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);

	const availableModes = (typeof cluster?.[ClusterConstants.DFF84.Attributes.AvailableModes] === "number") ? cluster[ClusterConstants.DFF84.Attributes.AvailableModes] : 0;
	const mowerState = (typeof cluster?.[ClusterConstants.DFF84.Attributes.DeviceStateROC] === "number") ? cluster[ClusterConstants.DFF84.Attributes.DeviceStateROC] : hex2Decimal(cluster[ClusterConstants.DFF84.Attributes.DeviceStateROC]);
	const errorCode = (typeof cluster?.[ClusterConstants.DFF84.Attributes.LastErrorROC] === "number") ? cluster[ClusterConstants.DFF84.Attributes.LastErrorROC] : hex2Decimal(cluster[ClusterConstants.DFF84.Attributes.LastErrorROC]);

	const handleMowerAction = (value: MowerActionModeValue) => {
		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.DFF84.CmdIds.ChangeMowerState,
			value: value,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const errorTxt = t([`clusters.DFF84.errorCodes.${errorCode ?? "defaultErrorCode"}`, "clusters.DFF84.errorCodes.defaultErrorCode"] as const) as string; // eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion
	const mowerStateTxt = t([`clusters.DFF84.mowerState.${mowerState ?? "defaultMowerState"}`, "clusters.DFF84.mowerState.defaultMowerState"] as const) as string; // eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion

	return (
		<>
			<ListItem sx={{ gap: 2 }}>
				<ListItemText primary={t("clusters.DFF84.control")} sx={{ minWidth: "fit-content" }} />
				<ListItemContainer sx={{ flexFlow: "wrap", gap: 1 }}>
					{Constants.AvailableMowerActionModes.filter((moverMode) => (Boolean(availableModes & moverMode.bitFlag))).map((moverMode) => (
						<Button
							className="btn-mower-mode"
							data-mower-mode={moverMode.value}
							key={moverMode.value}
							variant="contained"
							disabled={DISABLED_BUTTONS[moverMode.value].includes(mowerState)}
							onClick={() => (handleMowerAction(moverMode.value))}
						>
							{t(moverMode.l10n)}
						</Button>
					))}
				</ListItemContainer>
			</ListItem>
			<Divider />
			<ListItemButton component={Link} to={`${props.relativePathPrefix}graph/${props.deviceType.clusterId}/${props.deviceType.attributeId}`}>
				<ListItemText primary={t("clusters.DFF84.notification")} />
				<ListItemText primary={(errorCode === 0) ? mowerStateTxt : `${errorTxt}/${mowerStateTxt}`} />
				<ListItemIcon><icons.ChevronRight /></ListItemIcon>
			</ListItemButton>
			<Toast
				autoHideDuration={6000}
				severity="error"
				open={showGenericErrorMsg}
				onClose={setShowGenericErrorMsg}
				message={t("toast.genericErrorMsg")}
			/>
		</>
	);
};

DFF84.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		attributeId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
	}).isRequired,
	relativePathPrefix: PropTypes.string.isRequired,
};

export default DFF84;
