import { Capacitor, CapacitorHttp } from "@capacitor/core";
import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
// plugins
import { NativeLogin } from "../plugins/NativeLogin";
// services
import Constants from "./constants";
import { getGlientHttpUrlFromGlientWsUrl, getServerOption } from "./serverOption";
import { getRestHeaders } from "./rest-headers";
import { restartAccessTokenAutoRefresh } from "./accessTokenAutoRefresh";
import { getRefererUrl } from "./utils";
// types
import type { HttpOptions } from "@capacitor/core";
import type { OAuthData, OAuthDataWithOldTokenId } from "../types/user";
import type { SocketId } from "../types/misc";

interface RefreshTokensResult {
	expiresAt: number;
	socketId: SocketId;
}

const HTTP_OPTIONS = {
	webFetchExtra: {
		credentials: "include",
	},
} as const satisfies Partial<HttpOptions>;

const checkIsAccessTokenValid = async (): Promise<number | undefined> => {
	const params = new URLSearchParams({
		type: "iatv",
	});

	const options = {
		url: `${getGlientHttpUrlFromGlientWsUrl()}?${params.toString()}`,
		...HTTP_OPTIONS,
		headers: {
			...Capacitor.isNativePlatform() ? {
				Origin: globalThis.location.origin,
			} : {},
			...(await getRestHeaders()),
		},
	} as const satisfies HttpOptions;
	const { status, data } = await CapacitorHttp.get(options);
	if (status >= 200 && status <= 299) {
		if ("subject" in data && "displayName" in data && "expires" in data && "exp" in data) {
			const oAuthData = data as OAuthData;
			return oAuthData.exp * 1000;
		}
		throw new TypeError("OAuth data is missing", { cause: { status: status, data: data } });
	} else if (status === 401) {
		return undefined;
	} else {
		throw new RangeError("Invalid HTTP-Statuscode", { cause: { status: status, data: data } });
	}
};

const tryRefreshAccessToken = async (autoRefresh = false): Promise<RefreshTokensResult | undefined> => {
	const { channel, redirectUrl } = getServerOption();

	const params = new URLSearchParams({
		channel: channel,
		type: autoRefresh ? "arat" : "rat",
		state: "nativeweb", //globalThis.crypto.randomUUID(), // TODO: currently necessary for backend
	});

	const options = {
		url: `${redirectUrl}?${params.toString()}`,
		disableRedirects: true,
		responseType: "json",
		...HTTP_OPTIONS,
		headers: {
			...Capacitor.isNativePlatform() ? {
				Origin: globalThis.location.origin,
				Referer: getRefererUrl(),
			} : {},
			...(await getRestHeaders()),
		},
	} as const satisfies HttpOptions;
	const { status, data } = await CapacitorHttp.get(options);

	if (status >= 200 && status <= 299) {
		if ("subject" in data && "displayName" in data && "expires" in data && "exp" in data && "old_token_id" in data) {
			const oAuthData = data as OAuthDataWithOldTokenId;
			if (Capacitor.isNativePlatform()) {
				try {
					const { accessToken, refreshToken } = await NativeLogin.getTokens();

					if (accessToken !== "" && refreshToken !== "") {
						const config = await BackgroundGeolocation.getState();
						if (config.authorization) {
							config.authorization.accessToken = accessToken;
							config.authorization.refreshToken = refreshToken;
							await BackgroundGeolocation.setConfig(config);
						}
						// cancel old backgroundRefresh
						if (Capacitor.getPlatform() === Constants.Platform.Android) {
							await NativeLogin.restartBackgroundRefresh();
						}
					} else {
						return undefined;
					}
				} catch (error) {
					return undefined;
				}
			}
			return {
				expiresAt: oAuthData.exp * 1000,
				socketId: oAuthData.old_token_id,
			} as const satisfies RefreshTokensResult;
		}
		throw new TypeError("OAuth data is missing", { cause: { status: status, data: data } });
	} else if (status === 401) {
		return undefined;
	} else {
		throw new RangeError("Invalid HTTP-Statuscode", { cause: { status: status, data: data } });
	}
};

const updateWebsocketLifetime = async (socketId: SocketId): Promise<number | undefined> => {
	const { channel } = getServerOption();

	const params = new URLSearchParams({
		channel: channel,
		type: "uwl",
		state: globalThis.crypto.randomUUID(), // TODO: currently necessary for backend
	});

	const options = {
		url: `${getGlientHttpUrlFromGlientWsUrl()}/renew?${params.toString()}`,
		responseType: "json",
		...HTTP_OPTIONS,
		headers: {
			"Content-Type": "application/json",
			...Capacitor.isNativePlatform() ? {
				Origin: globalThis.location.origin,
				Referer: getRefererUrl(),
			} : {},
			...(await getRestHeaders()),
		},
		data: {
			id: socketId,
		},
	} as const satisfies HttpOptions;
	const { status, data } = await CapacitorHttp.post(options);

	if (status >= 200 && status <= 299) {
		if ("subject" in data && "displayName" in data && "expires" in data && "exp" in data) {
			const oAuthData = data as OAuthData;
			return oAuthData.exp * 1000;
		}
		throw new TypeError("OAuth data is missing", { cause: { status: status, data: data } });
	} else if (status === 410) { // socketId is not valid anymore
		return undefined;
	} else {
		throw new RangeError("Invalid HTTP-Statuscode", { cause: { status: status, data: data } });
	}
};

export const invalidateOldAccessToken = async (socketId: SocketId): Promise<void> => {
	const { invalidateTokenUrl } = getServerOption();

	const params = new URLSearchParams({
		action: "token_delete",
		type: "ioat",
	});

	const options = {
		url: `${invalidateTokenUrl}?${params.toString()}`,
		// responseType: "text", // or "json"
		...HTTP_OPTIONS,
		headers: {
			"Content-Type": "application/json",
			...Capacitor.isNativePlatform() ? {
				Origin: globalThis.location.origin,
				Referer: getRefererUrl(),
			} : {},
			...(await getRestHeaders()),
		},
		data: {
			id: socketId,
		},
	} as const satisfies HttpOptions;

	const { status, data } = await CapacitorHttp.post(options);

	if (status >= 200 && status <= 299) {
		// nothing todo
	} else {
		throw new RangeError("Invalid HTTP-Statuscode", { cause: { status: status, data: data } });
	}
};

let socketId: SocketId | undefined = undefined;

export const setSocketId = (newSocketId: SocketId | undefined) => {
	socketId = newSocketId;
};

export const refreshAccessToken = async (autoRefresh = false): Promise<boolean> => {
	const refreshTokensResult = await tryRefreshAccessToken(autoRefresh);
	if (refreshTokensResult === undefined) {
		return false; // refresh token expired
	}
	if (socketId) {
		const expiresAt = await updateWebsocketLifetime(socketId);
		if (expiresAt !== undefined) {
			restartAccessTokenAutoRefresh(expiresAt);
			await invalidateOldAccessToken(refreshTokensResult.socketId);
		}
	} else {
		// restartAccessTokenAutoRefresh(refreshTokensResult.expiresAt);
		console.warn("no socketId set");
	}

	return true;
};

export const checkIsAuthenticated = async (): Promise<boolean> => {
	const expiresAt = await checkIsAccessTokenValid();
	if (expiresAt !== undefined) {
		// restartAccessTokenAutoRefresh(expiresAt);
		return true;
	}

	const refreshingAccessTokenSuccessful = await refreshAccessToken();
	if (refreshingAccessTokenSuccessful) {
		return true;
	}

	return false;
};
